<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold">
        Education
      </v-toolbar-title>

      <v-spacer />
      <v-btn
        class="ml-5"
        :loading="educationLoading || uploadLoader || uploadImageLoader"
        @click="isUpdating ? update() : submit()"
      >
        Update Education
      </v-btn>
      <v-btn class="ml-5 error" @click="showConfirmModal = true">
        <v-icon color="white">
          {{ icons.mdiDelete }}
        </v-icon>
      </v-btn>
    </v-app-bar>

    <v-form
      class="education-form"
      @submit.prevent="isUpdating ? update() : submit()"
    >
      <v-row>
        <v-col cols="4" align="center">
          <ImageUploader
            @onImageLoad="imageLoad"
            :form="form"
            :auto-update="false"
            v-if="!form.thumb_nail_from_s3"
          />
          <!-- <p v-if="!form.thumb_nail_from_s3 && !(form.photos.length > 0)">
            or
          </p>
          <v-text-field
            v-model.trim="form.thumb_nail_from_s3"
            v-if="!(form.photos.length > 0)"
            class="input__outlined--regular"
            label="Thumbnail from AWS"
            outlined
            :error-messages="form.$getError('thumb_nail_from_s3')"
            @input="form.$clearError('thumb_nail_from_s3')"
          /> -->

          <v-divider class="mb-7" />

          <div
            class="upload-container pointer"
            :class="{ 'has-error': form.$getError('video') }"
            v-if="!form.video_from_s3"
            @click="uploadThumbnail('video')"
          >
            <v-img
              :src="require('@/assets/icons/file-upload-placeholder.svg')"
              alt="File Upload Placeholder"
              height="150px"
              width="auto"
            />

            <div class="upload-label mt-3">
              <span variant="primary"> Upload Introduction Video </span>
            </div>
            <span v-if="form.$getError('video')" class="error--text">
              {{ form.$getError('video')[0] }}
            </span>

            <v-overlay :absolute="true" :value="uploadLoader">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-overlay>
          </div>

          <v-progress-linear
            color="light-blue"
            height="10"
            v-if="!(uploadProgress === 0 || uploadProgress === 100)"
            :value="uploadProgress"
            striped
          />

          <div
            class="upload-container pointer mt-5"
            v-if="checkIVideoThumbNail(form)"
          >
            <v-btn
              fab
              class="radius__button text-none remove-img error"
              x-small
              depressed
              @click="removeVideo()"
            >
              <v-icon small color="white">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
            <div v-if="isBase64(checkIVideoThumbNail(form))">
              <video controls :src="checkIVideoThumbNail(form)">
                Your browser does not support the video tag.
              </video>
            </div>
            <div v-else>
              <video-player
                v-if="checkIVideoIsProcessing(form)"
                ref="videoPlayer"
                class="vjs-custom-skin"
                :options="playerOptions"
                @ready="onPlayerReady(checkIVideoThumbNail(form))"
              >
              </video-player>
              <div v-else class="mt-3">
                <span variant="primary"> Video is still processing </span>
              </div>
            </div>
          </div>
          <!-- <p
            v-if="
              !form.video_from_s3 &&
                !(form.video.length > 0) &&
                !checkIVideoThumbNail(form)
            "
          >
            or
          </p>
          <v-text-field
            v-model.trim="form.video_from_s3"
            v-if="!(form.video.length > 0) && !checkIVideoThumbNail(form)"
            class="input__outlined--regular"
            label="Video from AWS"
            outlined
            :error-messages="form.$getError('video_from_s3')"
            @input="form.$clearError('video_from_s3')"
          /> -->
        </v-col>
        <v-col cols="8">
          <v-row>
            <v-col cols="9">
              <v-text-field
                v-model.trim="form.title"
                class="input__outlined--regular"
                label="Education Title"
                outlined
                :error-messages="form.$getError('title')"
                @input="form.$clearError('title')"
              />
            </v-col>
            <v-col cols="3">
              <v-switch
                v-model="form.is_free"
                :error-messages="form.$getError('is_free')"
                label="Free"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <CategorySelectBox
                :items="categories"
                :selections="form.categories"
              />
            </v-col>
          </v-row>

          <ContentEditor v-model="form.content" />

          <!-- <h2 class="my-5">Alternative Educations</h2>
          <v-card outlined class="pa-5">
            <div class="d-flex justify-space-between align-center mb-3">
              <p>Add Alternative Educations</p>
              <v-btn @click="showAltEducationForm = true">
                Add
              </v-btn>
            </div>

            <v-card
              flat
              outlined
              class="mb-3"
              v-if="form.alternative_educations.length > 0"
            >
              <v-list-item-group>
                <v-list-item
                  v-for="(altEducation, index) in form.alternative_educations"
                  :key="index"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{
                      altEducation.title
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="(item, i) in menuItems"
                          link
                          :key="i"
                        >
                          <v-list-item-title
                            @click="menuAction(item, altEducation, index)"
                            >{{ item }}</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-card>
          </v-card> -->
        </v-col>
      </v-row>
    </v-form>

    <div v-if="showAltEducationForm">
      <AlternativeEducationForm
        :show="showAltEducationForm"
        :currentAltEducation="currentAltEducation"
        :isEdit="isEdit"
        :loading="educationLoading"
        @onSave="onAddAltEducation"
        @onUpdate="onUpdateAltEducation"
        @onCancel="cancelAltEducation"
      />
    </div>

    <ConfirmModal
      title="Delete Education"
      message="Do you want to remove this education?"
      v-model="showConfirmModal"
      :loading="educationLoading"
      @cancel="showConfirmModal = false"
      @confirm="remove()"
    />

    <MediaUploader
      :ref="'triggerUpload'"
      @onMediaLoad="onMediaLoad"
      :mediaType="mediaType"
      @startUpload="startUpload"
    ></MediaUploader>
  </div>
</template>

<script>
import { mdiDelete, mdiDotsVertical, mdiClose } from '@mdi/js'
import { mapState, mapActions } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ImageUploader from '@/components/ImageUploader'
import ContentEditor from '@/components/ContentEditor'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { programMenu } from '@/utils/constants'
import AlternativeEducationForm from '@/components/education/AlternativeEducationForm'
import { objectToFormData } from '@/utils/jsonToFormData'
import MediaUploader from '@/components/MediaUploader'
import CategorySelectBox from '@/components/education/CategorySelectBox'
import VideoPlayer from 'vue-videojs7/src/components/VideoPlayer.vue'
import uploadFile from '@/utils/uploadFile'

export default {
  name: 'EducationItem',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon,
    ImageUploader,
    ContentEditor,
    ConfirmModal,
    AlternativeEducationForm,
    MediaUploader,
    CategorySelectBox,
    VideoPlayer,
  },
  computed: {
    ...mapState('education', {
      form: (state) => state.educationForm,
      educationLoading: (state) => state.educationLoading,
    }),
    ...mapState('educationCategory', {
      categories: (state) => state.categories,
    }),
    ...mapState({
      uploadProgress: (state) => state.uploadProgress,
    }),
    player() {
      return this.$refs.videoPlayer.player
    },
  },
  data() {
    return {
      icons: {
        mdiDelete,
        mdiDotsVertical,
        mdiClose,
      },
      menuItems: [programMenu().EDIT, programMenu().DELETE],
      currentAltEducation: {
        title: '',
        link: '',
        thumb_nail: null,
      },
      showConfirmModal: false,
      showAltEducationForm: false,
      isEdit: false,
      editIndex: null,
      mediaType: null,
      uploadLoader: false,
      uploadImageLoader: false,
      imageState: null,
      playerOptions: {
        autoplay: false,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
      },
    }
  },
  mounted() {
    this.getExercieCategories()
    this.getEducation({ id: this.$route.params.id })
  },
  methods: {
    ...mapActions({
      getExercieCategories: 'educationCategory/getExercieCategories',
      createEducation: 'education/createEducation',
      getEducation: 'education/getEducation',
      updateEducation: 'education/updateEducation',
      deleteEducation: 'education/deleteEducation',
      updateAltEducation: 'education/updateAltEducation',
      deleteAltEducation: 'education/deleteAltEducation',
      addAltEducation: 'education/addAltEducation',
      updateThumbNail: 'education/updateThumbNail',
      updateVideo: 'education/updateVideo',
    }),
    startUpload() {
      this.uploadLoader = true
    },
    isUpdating() {
      return !this.$route.name.includes('create')
    },
    onUpdateAltEducation(altEducation) {
      let form = { ...altEducation }

      form.thumb_nail = form.thumb_nail[0] ? form.thumb_nail[0].file : null

      form = objectToFormData(form)
      this.updateAltEducation({
        education_id: this.$route.params.id,
        alt_education_id: altEducation.id,
        data: form,
      }).then(() => {
        this.showAltEducationForm = false
        this.cancelAltEducation()
      })
    },
    onAddAltEducation(altEducation) {
      let form = { ...altEducation }

      form.thumb_nail = form.thumb_nail[0].file
      form = objectToFormData(form)
      this.addAltEducation({
        education_id: this.$route.params.id,
        data: form,
      }).then(() => {
        this.showAltEducationForm = false
        this.cancelAltEducation()
      })
    },
    cancelAltEducation() {
      this.editIndex = null
      this.isEdit = false
      this.resetCurrentAltEducation()
      this.showAltEducationForm = false
    },
    async menuAction(menu, altEducation, index) {
      switch (menu) {
        case programMenu().EDIT:
          this.isEdit = true
          this.currentAltEducation = altEducation
          this.editIndex = index
          this.showAltEducationForm = true
          break
        case programMenu().DELETE:
          await this.deleteAltEducation({
            education_id: this.$route.params.id,
            alt_education_id: altEducation.id,
          })
          break
      }
    },
    resetCurrentAltEducation() {
      this.currentAltEducation = {
        title: '',
        link: '',
        thumb_nail: null,
      }
    },
    removeVideo() {
      this.form.video = []
    },
    uploadThumbnail(state) {
      if (state === 'video') {
        this.mediaType = 'video'
      } else {
        this.mediaType = 'image'
      }

      this.$refs.triggerUpload.openFile()
      this.imageState = state
    },
    async imageLoad(photos) {
      this.uploadImageLoader = true
      const form = new FormData()
      if (photos.file) {
        const thumbNailId = await uploadFile(photos.file)
        form.append('thumb_nail_id', thumbNailId)
      }

      await this.updateThumbNail({ education_id: this.form.id, data: form })
      this.uploadImageLoader = false
    },
    async onMediaLoad(data) {
      switch (this.imageState) {
        case 'video':
          this.form.video = []
          this.form.video = [
            {
              file: data.file.get('file'),
              url: data.url,
            },
          ]
          const { video } = this.form.$data()

          const form = new FormData()
          this.uploadLoader = true
          if (video.length > 0) {
            const videoId = await uploadFile(video[0].file)
            form.append('video_id', videoId)
          }

          await this.updateVideo({ education_id: this.form.id, data: form })

          this.uploadLoader = false
          this.form.$clearError('video')
          break
      }
    },
    checkIVideoThumbNail(image) {
      return image?.video?.[0]?.url || image?.video?.url
    },
    checkIVideoIsProcessing(image) {
      return (
        image?.video?.[0]?.video_transform_job?.progress === 100 ||
        image?.video?.video_transform_job?.progress === 100
      )
    },
    update() {
      const { photos, video, ...education } = this.form.$data()

      const form = new FormData()
      Object.keys(education).forEach((key) => form.set(key, education[key]))
      if (photos.length > 0) {
        form.append('thumb_nail', photos[0]?.file)
      }
      if (video.length > 0) {
        form.append('video', video[0]?.file)
      }

      const categories = JSON.stringify(education.categories)
      form.append('categories', categories)

      this.updateEducation({ id: this.form.id, data: form })
    },
    submit() {
      const { photos, video, ...education } = this.form.$data()

      const form = new FormData()
      Object.keys(education).forEach((key) => form.set(key, education[key]))
      form.append('thumb_nail', photos[0]?.file)
      form.append('video', video[0]?.file)
      this.createEducation(form)
    },
    remove() {
      this.deleteEducation(this.$route.params.id).then(() => {
        this.$router.push({ name: 'education' })
      })
    },
    playVideo(source) {
      const video = {
        withCredentials: false,
        type: 'application/x-mpegurl',
        src: source,
      }
      this.player.reset()
      this.player.src(video)
    },
    isBase64(src) {
      const regex =
        /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/
      if (regex.test(src)) {
        return !src.includes('m3u8')
      } else {
        return true
      }
    },
    onPlayerReady(src) {
      setTimeout(() => {
        this.playVideo(this.checkIVideoThumbNail(this.form))
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-container {
  width: 100%;
  height: 300px;
  border-radius: 6px;
  border: dashed 2px #2c66d54d;
  background-color: #2c66d50a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &.has-error {
    border: dashed 2px #fa4856;
  }

  .v-image {
    flex-grow: 0;
  }

  .upload-label span {
    box-shadow: 0 12px 20px rgba(44, 102, 213, 0.4);
    border-color: #0062cc;
    background-color: #0062cc;
    color: #ffffff;
    padding: 4px 32px;
    border-radius: 8px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-bottom: 12px;
  }

  video {
    height: 300px;
    width: 100%;
  }
}

.remove-img {
  position: absolute;
  right: -15px;
  top: -15px;
}
</style>
<style lang="scss">
.video-js {
  position: relative;
  width: 100%;
  height: auto;
  .vjs-tech {
    position: relative;
    width: 100%;
    height: 300px;
  }
}
</style>
